import React from "react";
import "./App.css";
import UnderConstruction from "./UnderConstruction";

const App = () => {
  return (
    <div className="App">
      {/* <Routing /> */}
      <UnderConstruction />
    </div>
  );
};

export default App;
