import React from "react";
import "./App.css";
import under from "./Assets/coming.png";

const UnderConstruction = () => {
  return (
    <div className="under">
      <img src={under} alt="alt" className="under-img" />
      <h1 className="title">Marine Deck</h1>
    </div>
  );
};

export default UnderConstruction;
